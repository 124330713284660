/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

.swiper-pagination-fraction,
.swiper-pagination-custom,
.swiper-container-horizontal>.swiper-pagination-bullets {
  bottom: none !important;
  height: 20px;
}

.swiper-pagination {
  top: 15% !important;
}

.joyride-step__container {
  border-radius: 25px !important;
}

.joyride-step__close {
  right: 15px !important;
  top: 15px !important;
}

.custom-modal {
  --backdrop-opacity: 1;
  backdrop-filter: blur(3px);

  &::part(backdrop) {
    background: rgba(0, 0, 0, 0.8);
  }

  &::part(handle) {
    background: var(--ion-color-primary);
    width: 150px;
    height: 6px;
  }

  &::part(content) {
    border-radius: 0px;
    box-shadow: 0px 0px 20px 5px rgb(0 0 0 / 32%);
  }
}

ion-list-header svg {
  width: 70%;
}

ion-accordion-group {
  ion-accordion {
    .ion-accordion-toggle-icon {
      color: #ffffff;
    }
  }
}

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";


@import '~swiper/css/bundle';